import types from '../../mutation-types';
import NotificationsAPI from '../../../api/notifications';

export const actions = {
  get: async ({ commit, dispatch }, { page = 1 } = {}) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: true });
    try {
      const {
        data: {
          data: { payload, meta },
        },
      } = await NotificationsAPI.get({ page });
      commit(types.CLEAR_NOTIFICATIONS);
      commit(types.SET_NOTIFICATIONS, payload);
      commit(types.SET_NOTIFICATIONS_META, meta);
    } catch (error) {
      throw new Error('Erro ao buscar notificações:', error);
      // Opcional: Disparar uma mutation para tratar o erro
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: false });
      // Atualiza o app badge após buscar notificações
      await dispatch('updateAppBadge', null, { root: true });
    }
  },
  index: async (
    { commit, dispatch },
    { page = 1, status, type, sortOrder } = {}
  ) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: true });
    try {
      const {
        data: {
          data: { payload, meta },
        },
      } = await NotificationsAPI.get({
        page,
        status,
        type,
        sortOrder,
      });
      commit(types.SET_NOTIFICATIONS, payload);
      commit(types.SET_NOTIFICATIONS_META, meta);
      if (payload.length < 15) {
        commit(types.SET_ALL_NOTIFICATIONS_LOADED);
      }
    } catch (error) {
      throw new Error('Erro ao indexar notificações:', error);
      // Opcional: Disparar uma mutation para tratar o erro
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: false });
      // Atualiza o app badge após indexar notificações
      await dispatch('updateAppBadge', null, { root: true });
    }
  },
  unReadCount: async ({ commit, dispatch } = {}) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: true });
    try {
      const { data } = await NotificationsAPI.getUnreadCount();
      commit(types.SET_NOTIFICATIONS_UNREAD_COUNT, data);
    } catch (error) {
      throw new Error(
        'Erro ao buscar contagem de notificações não lidas:',
        error
      );
      // Opcional: Disparar uma mutation para tratar o erro
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: false });
      // Atualiza o app badge após buscar a contagem
      await dispatch('updateAppBadge', null, { root: true });
    }
  },
  read: async (
    { commit, dispatch },
    { id, primaryActorType, primaryActorId, unreadCount }
  ) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: true });
    try {
      await NotificationsAPI.read(primaryActorType, primaryActorId);
      commit(types.SET_NOTIFICATIONS_UNREAD_COUNT, unreadCount - 1);
      commit(types.READ_NOTIFICATION, {
        id,
        read_at: new Date().toISOString(),
      }); // Definindo read_at como timestamp atual
    } catch (error) {
      throw new Error('Erro ao marcar notificação como lida:', error);
      // Opcional: Disparar uma mutation para tratar o erro
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: false });
      // Atualiza o app badge após marcar como lida
      await dispatch('updateAppBadge', null, { root: true });
    }
  },
  unread: async ({ commit, dispatch }, { id }) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: true });
    try {
      await NotificationsAPI.unRead(id);
      commit(types.READ_NOTIFICATION, { id, read_at: null }); // Definindo read_at como null
    } catch (error) {
      throw new Error('Erro ao marcar notificação como não lida:', error);
      // Opcional: Disparar uma mutation para tratar o erro
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: false });
      // Atualiza o app badge após marcar como não lida
      await dispatch('updateAppBadge', null, { root: true });
    }
  },
  readAll: async ({ commit, dispatch }) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: true });
    try {
      await NotificationsAPI.readAll();
      commit(types.UPDATE_ALL_NOTIFICATIONS);
    } catch (error) {
      throw new Error(
        'Erro ao marcar todas as notificações como lidas:',
        error
      );
      // Opcional: Disparar uma mutation para tratar o erro
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: false });
      // Atualiza o app badge após marcar todas como lidas
      await dispatch('updateAppBadge', null, { root: true });
    }
  },
  delete: async ({ commit, dispatch }, { notification, count }) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isDeleting: true });
    try {
      await NotificationsAPI.delete(notification.id);
      commit(types.DELETE_NOTIFICATION, { notification, count });
    } catch (error) {
      throw new Error('Erro ao deletar notificação:', error);
      // Opcional: Disparar uma mutation para tratar o erro
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isDeleting: false });
      // Atualiza o app badge após deletar uma notificação
      await dispatch('updateAppBadge', null, { root: true });
    }
  },
  deleteAllRead: async ({ commit, dispatch }) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isDeleting: true });
    try {
      await NotificationsAPI.deleteAll({
        type: 'read',
      });
      commit(types.DELETE_READ_NOTIFICATIONS);
    } catch (error) {
      throw new Error('Erro ao deletar todas as notificações lidas:', error);
      // Opcional: Disparar uma mutation para tratar o erro
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isDeleting: false });
      // Atualiza o app badge após deletar notificações lidas
      await dispatch('updateAppBadge', null, { root: true });
    }
  },
  deleteAll: async ({ commit, dispatch }) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isDeleting: true });
    try {
      await NotificationsAPI.deleteAll({
        type: 'all',
      });
      commit(types.DELETE_ALL_NOTIFICATIONS);
    } catch (error) {
      throw new Error('Erro ao deletar todas as notificações:', error);
      // Opcional: Disparar uma mutation para tratar o erro
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isDeleting: false });
      // Atualiza o app badge após deletar todas as notificações
      await dispatch('updateAppBadge', null, { root: true });
    }
  },
  snooze: async ({ commit, dispatch }, { id, snoozedUntil }) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: true });
    try {
      const response = await NotificationsAPI.snooze({
        id,
        snoozedUntil,
      });

      const {
        data: { snoozed_until = null },
      } = response;
      commit(types.SNOOZE_NOTIFICATION, {
        id,
        snoozed_until,
      });
    } catch (error) {
      throw new Error('Erro ao adiar notificação:', error);
      // Opcional: Disparar uma mutation para tratar o erro
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: false });
      // Atualiza o app badge após adiar uma notificação, se necessário
      await dispatch('updateAppBadge', null, { root: true });
    }
  },

  updateNotification: async ({ commit, dispatch }, data) => {
    commit(types.UPDATE_NOTIFICATION, data);
    // Atualiza o app badge após atualizar uma notificação
    await dispatch('updateAppBadge', null, { root: true });
  },

  addNotification: async ({ commit, dispatch }, data) => {
    commit(types.ADD_NOTIFICATION, data);
    // Atualiza o app badge após adicionar uma nova notificação
    await dispatch('updateAppBadge', null, { root: true });
  },
  deleteNotification: async ({ commit, dispatch }, data) => {
    commit(types.DELETE_NOTIFICATION, data);
    // Atualiza o app badge após deletar uma notificação
    await dispatch('updateAppBadge', null, { root: true });
  },
  clear: async ({ commit, dispatch }) => {
    commit(types.CLEAR_NOTIFICATIONS);
    // Atualiza o app badge após limpar todas as notificações
    await dispatch('updateAppBadge', null, { root: true });
  },

  setNotificationFilters: async ({ commit, dispatch }, filters) => {
    commit(types.SET_NOTIFICATION_FILTERS, filters);
    // Opcional: Atualiza o app badge se os filtros afetarem a contagem de notificações
    await dispatch('updateAppBadge', null, { root: true });
  },
  updateNotificationFilters: async ({ commit, dispatch }, filters) => {
    commit(types.UPDATE_NOTIFICATION_FILTERS, filters);
    // Opcional: Atualiza o app badge se os filtros afetarem a contagem de notificações
    await dispatch('updateAppBadge', null, { root: true });
  },
};
