export default function appBadgePlugin(store) {
  let previousCount = 0;

  store.subscribe(mutation => {
    if (mutation.type.startsWith('notifications/')) {
      const currentCount =
        store.getters['notifications/getUnreadNotificationCount'];
      if (currentCount !== previousCount) {
        previousCount = currentCount;
        store.dispatch('updateAppBadge');
      }
    }
  });
}
