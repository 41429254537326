// app/javascript/dashboard/store/modules/notifications/mutations.js

import Vue from 'vue';
import types from '../../mutation-types';

export const mutations = {
  [types.SET_NOTIFICATIONS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.CLEAR_NOTIFICATIONS]: $state => {
    Vue.set($state, 'records', {});
    Vue.set($state.uiFlags, 'isAllNotificationsLoaded', false);
    // Remover 'unreadCount' do estado meta
    Vue.set($state.meta, 'unreadCount', 0);
  },
  [types.SET_NOTIFICATIONS_META]: ($state, data) => {
    const {
      count,
      current_page: currentPage,
      unread_count: unreadCount, // Remover se optar por não manter 'unreadCount'
    } = data;

    Vue.set($state.meta, 'count', count);
    Vue.set($state.meta, 'currentPage', currentPage);
    // Remover 'unreadCount' se não for necessário
    // Vue.set($state.meta, 'unreadCount', unreadCount);
  },
  [types.SET_NOTIFICATIONS_UNREAD_COUNT]: ($state, count) => {
    // Remover essa mutation se optar por não manter 'unreadCount'
    // Vue.set($state.meta, 'unreadCount', count < 0 ? 0 : count);
  },
  [types.SET_NOTIFICATIONS]: ($state, data) => {
    data.forEach(notification => {
      // Encontrar notificação existente com o mesmo 'primary_actor_id' (supondo que seja único)
      const existingNotification = Object.values($state.records).find(
        record => record.primary_actor_id === notification.primary_actor_id
      );
      // Remover notificação duplicada se existir
      if (existingNotification) {
        Vue.delete($state.records, existingNotification.id);
      }
      Vue.set($state.records, notification.id, {
        ...($state.records[notification.id] || {}),
        ...notification,
      });
    });
  },
  [types.READ_NOTIFICATION]: ($state, { id, read_at }) => {
    if ($state.records[id]) {
      Vue.set($state.records[id], 'read_at', read_at);
    }
  },
  [types.UPDATE_ALL_NOTIFICATIONS]: $state => {
    Object.values($state.records).forEach(item => {
      // Corrigir para definir 'read_at' como timestamp ISO
      Vue.set($state.records[item.id], 'read_at', new Date().toISOString());
    });
  },

  [types.ADD_NOTIFICATION]($state, data) {
    const { notification, count } = data;
    Vue.set($state.records, notification.id, {
      ...($state.records[notification.id] || {}),
      ...notification,
    });
    Vue.set($state.meta, 'count', count);
    // Não atualizar 'unreadCount' diretamente
  },
  [types.UPDATE_NOTIFICATION]($state, data) {
    const { notification, count } = data;
    Vue.set($state.records, notification.id, {
      ...($state.records[notification.id] || {}),
      ...notification,
    });
    Vue.set($state.meta, 'count', count);
    // Não atualizar 'unreadCount' diretamente
  },
  [types.DELETE_NOTIFICATION]($state, data) {
    const { notification, count } = data;
    Vue.delete($state.records, notification.id);
    Vue.set($state.meta, 'count', count);
    // Não atualizar 'unreadCount' diretamente
  },
  [types.SET_ALL_NOTIFICATIONS_LOADED]: $state => {
    Vue.set($state.uiFlags, 'isAllNotificationsLoaded', true);
  },

  [types.DELETE_READ_NOTIFICATIONS]: $state => {
    Object.values($state.records).forEach(item => {
      if (item.read_at) {
        Vue.delete($state.records, item.id);
      }
    });
  },
  [types.DELETE_ALL_NOTIFICATIONS]: $state => {
    Vue.set($state, 'records', {});
    Vue.set($state.meta, 'count', 0);
    // Remover 'unreadCount' se não estiver sendo usado
  },

  [types.SNOOZE_NOTIFICATION]: ($state, { id, snoozed_until }) => {
    if ($state.records[id]) {
      Vue.set($state.records[id], 'snoozed_until', snoozed_until);
    }
  },

  [types.SET_NOTIFICATION_FILTERS]: ($state, filters) => {
    Vue.set($state, 'notificationFilters', filters);
  },
  [types.UPDATE_NOTIFICATION_FILTERS]: ($state, filters) => {
    Vue.set($state, 'notificationFilters', {
      ...$state.notificationFilters,
      ...filters,
    });
  },
};
